/* eslint-disable*/
import axios from 'axios';

const localData = localStorage.getItem("userData")
  const parsedData = JSON.parse(localData)
  const token = parsedData === null ? null : parsedData.accessToken

const apiAuthenticatedCall = axios.create({
  // baseURL: 'https://apiserver.sociomeeofficial.com',
  // baseURL: 'http://localhost:2000',
  // baseURL: 'https://devapiserver.sociomessage.com', // Replace with your API base URL
   baseURL: "https://route.sociomessage.com" ,
  //baseURL:"https://backendapi.meessages.com",
  timeout: 10000, // Set a reasonable timeout
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add an interceptor to set the Bearer token for each request
apiAuthenticatedCall.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${token}`
    return config;
  },
  (error) => {
    return Promise.reject(error)
  }
);

// Add an interceptor to handle responses when session is expired then re render to login page
apiAuthenticatedCall.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const statusCode = error.response.status

      if (statusCode === 403) {
        localStorage.clear()
        window.location.reload()
        console.error('Forbidden: You do not have permission to access this resource.')
      } else {
        console.error('Error status code:', statusCode)
      }
    } else if (error.request) {
      console.error('No response received:', error.request)
    } else {
      console.error('Error setting up the request:', error.message)
    }
    return Promise.reject(error)
  }
);


export default apiAuthenticatedCall

/* eslint-disable*/
import axios from "axios"

const instance = axios.create({
    // baseURL: "https://apiserver.sociomeeofficial.com" //--------Production Server,
    // baseURL: "https://apiserver.sociomeeofficial.com",----- BackupServer,
    //  baseURL: "https://devapiserver.sociomessage.com" //----- Development server,
    // baseURL: "http://localhost:2000",
     baseURL: "https://route.sociomessage.com" 
      // baseURL:"https://backendapi.meessages.com"
    
    // headers: {
    //     Authorization: `Bearer ${JSON.parse(localStorage.getItem("userData"))?.accessToken}`,
    //     "Content-Type": "application/json"
    // }
    
})

instance.interceptors.response.use(
    (response) => {
      // Do something with the successful response
      return response;
    },
    (error) => {
      // Do something with the error response
      if (error.response) {
        const statusCode = error.response.status;
  
        if (statusCode === 403) {
          // Handle 403 Forbidden error
          localStorage.clear()
          window.location.reload()
          console.error('Forbidden: You do not have permission to access this resource.')
        } else {
          // Handle other status codes
          console.error('Error status code:', statusCode)
        }
      } else if (error.request) {
        console.error('No response received:', error.request)
      } else {
        console.error('Error setting up the request:', error.message)
      }
      return Promise.reject(error)
    }
  );

export default instance
